// extracted by mini-css-extract-plugin
export var header = "P_H";
export var item = "P_cg";
export var itemImage = "P_cD";
export var itemIsExternal = "P_cK";
export var itemIsInView = "P_cH";
export var itemIsLinked = "P_cJ";
export var itemText = "P_cF";
export var itemTime = "P_cL";
export var itemTimeIsBig = "P_cM";
export var logo = "P_bH";
export var root = "P_b";